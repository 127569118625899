@tailwind base;
@tailwind components;
@tailwind utilities;

body * {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Roboto, color-emoji, sans-serif;
}

html.noscroll,
html.noscroll body {
  height: 100%;
  width: 100vw;
  position: fixed;
  overflow: hidden;
}

html.noscroll #__next,
html.noscroll #__next > div:first-child,
html.noscroll #__next > div:first-child > section {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

body {
  @apply bg-culturedPearlGrey;
  @apply text-black;

  @apply dark:bg-mineShaftGrey;
  @apply dark:text-white;
}

/* LOADER STYLES */

#nprogress .bar {
  background: #1a2243;
}

#nprogress .peg {
  display: none;
}

@media (prefers-color-scheme: dark) {
  #nprogress .bar {
    background: #f76c6c;
  }
}

/* VIDEOJS STYLES */

.video-js,
.vjs-tech {
  background: none !important;
}

.fullcard .video-js {
  height: 100% !important;
  padding-top: 0 !important;
}

/* SPINNER STYLES */

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@font-face {
  font-family: 'color-emoji';
  src: local('Apple Color Emoji'), local('Segoe UI Emoji'),
    local('Segoe UI Symbol'), local('Noto Color Emoji');
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/roboto-bold-webfont.woff2') format('woff2'),
    url('../public/fonts/roboto-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/roboto-bolditalic-webfont.woff2') format('woff2'),
    url('../public/fonts/roboto-bolditalic-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/roboto-medium-webfont.woff2') format('woff2'),
    url('../public/fonts/roboto-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/roboto-mediumitalic-webfont.woff2') format('woff2'),
    url('../public/fonts/roboto-mediumitalic-webfont.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/roboto-italic-webfont.woff2') format('woff2'),
    url('../public/fonts/roboto-italic-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/roboto-regular-webfont.woff2') format('woff2'),
    url('../public/fonts/roboto-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/roboto-thin-webfont.woff2') format('woff2'),
    url('../public/fonts/roboto-thin-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/roboto-thinitalic-webfont.woff2') format('woff2'),
    url('../public/fonts/roboto-thinitalic-webfont.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}
